
const firebaseConfig = {
      
    apiKey: process.env.API_KEY,

    authDomain: process.env.AUTH_DOMAIN,

    databaseURL: process.env.DATABASE_URL,

    projectId: process.env.PROJECT_ID,

    storageBucket: process.env.STORAGE_BUCKET,

    messagingSenderId: process.env.MESSAGIN_SENDER_ID,

    appId: process.env.APP_ID

};

firebase.initializeApp(firebaseConfig);

const submitClick = (e) => {

    e.preventDefault();

    let firebaseRef = firebase.database().ref();

    firebaseRef.once("value", function(snapshot) {

        let datos = snapshot.val();

        const input_usuario = document.getElementById('input_usuario')

        let respuesta = document.getElementById('respuesta');

        for (let i = 0; i < datos.length; i++) {
            if (datos[i].usuario === input_usuario.value.split("@")[0].toLowerCase() || datos[i].cel ===input_usuario.value) {

                if (datos[i].diponible >= 3) {

                    respuesta.innerHTML = `
                        <p class="result">Tienes ${datos[i].diponible} soles disponibles</p>
                        <p class="info-result">Has estado ${datos[i].dinero_primeros_cien} ${datos[i].dinero_primeros_cien === 1? 'vez' : 'veces'} entre los primeros 100</p>
                        <p class="info-result">Has estado ${datos[i].dinero_segundos_cien * 2} ${datos[i].dinero_segundos_cien * 2 === 1? 'vez' : 'veces'} entre los segundos 100</p>
                        <p class="info-result">Has acumulado ${datos[i].dinero_acumulado} ${datos[i].dinero_acumulado === 1? 'sol' : 'soles'} respondiendo encuestas</p>
                        <p class="info-result">Has retirado ${datos[i].retirado} ${datos[i].retirado === 1? 'sol' : 'soles'} respondiendo encuestas</p>
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSfJpMyg_Km1Yhx3b4oBKpU_1rm9AV958eZ3P-FUmL9xfo_42w/viewform" target='_blank'><button id='dp-b'>Retirar</button></a>
                        <p class="aviso">
                            El premio mayor se transfiere inmediatamente al ganador. Para cobrar lo recaudado en #Fiel Seguidor y #FielEnPotencia debes acumular mínimo S/.3. Podrán ser transferidos a tu cuenta (BCP/BBVA) o también vía Yape y Plin.
                        </p>
                    `

                } else {
                    respuesta.innerHTML = `
                        <p class="result">Tienes ${datos[i].diponible} soles disponibles</p>
                        <p class="info-result">Sigue respondiendo encuestas para seguir acumulando</p>
                        <p class="info-result">Has estado ${datos[i].dinero_primeros_cien} ${datos[i].dinero_primeros_cien === 1? 'vez' : 'veces'} entre los primeros 100</p>
                        <p class="info-result">Has estado ${datos[i].dinero_segundos_cien * 2} ${datos[i].dinero_segundos_cien * 2 === 1? 'vez' : 'veces'} entre los segundos 100</p>
                        <p class="info-result">Has acumulado ${datos[i].dinero_acumulado} ${datos[i].dinero_acumulado === 1? 'sol' : 'soles'} respondiendo encuestas</p>
                        <p class="info-result">Has retirado ${datos[i].retirado} ${datos[i].retirado === 1? 'sol' : 'soles'} respondiendo encuestas</p>
                        <a href="https://www.facebook.com/EncuestamePe-100756808075337" target='_blank'><button id='dp-b'  onclick='rp()'>Responder encuestas</button></a>
                        <p class="aviso">
                            El premio mayor se transfiere inmediatamente al ganador. Para cobrar lo recaudado en #Fiel Seguidor y #FielEnPotencia debes acumular mínimo S/.3. Podrán ser transferidos a tu cuenta (BCP/BBVA) o también vía Yape y Plin.
                        </p>
                    `
                }

                break;

            }

            if (datos[datos.length - 1].usuario !== input_usuario.value || datos[datos.length - 1].cel ===input_usuario.value) {
                respuesta.innerHTML = `
                    <p class="result">Usuario o número de teléfono incorrecto</p>
                    <button onclick='rp()' id='dp-b'>Volver a consultar</button>
                `
            }
        } 
    })

    $('.container-main').css('display', 'none');
    $('#respuesta').css('display', 'block');
}

$(document).ready(function() {
    $('#contactSend').click(submitClick);
    $('#dp-b').click(rp);
});
